h1 {
  color: whitesmoke;
  padding: 1em;
  font-size: x-large;
  margin-bottom: 2em;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  grid-auto-rows: auto;
}

.photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
