.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #333;
  color: #ffffff;
  padding: 5px;
  font-weight: 200;
  font-size: small;
  padding: 1em;
}

.contact {
  display: flex;
  flex-direction: column;
}

.name {
  margin: 0;
  padding-bottom: 1em;
  border-bottom: #333;
}

.email {
  padding-top: 0.5em;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 1em;
}

.telephone {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 1em;
}

.socialMedia {
  display: flex;
  gap: 1em;
}

.media {
  display: flex;
  align-items: center;
  gap: 1em;
}

a {
  color: inherit; /* Ustawia kolor tekstu na dziedziczony od rodzica */
  text-decoration: none; /* Usuwa podkreślenie */
  cursor: pointer; /* Zmienia kursor na wskazujący na interakcję */
}
