body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: "Montserrat";
  font-weight: 600;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100dvh;
}

.main-content {
  flex: 1;
  background-color: #292828;
  position: relative;
}

.home-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../public/img/background.jpg") no-repeat center center fixed;
  background-size: cover;
}
